<template>
    <v-card class="pa-5 py-10 box-shadow mr-3" tile>
        <span class="f20 fw600">Welcome back!</span>
        <v-divider class="l-primary my-2 mb-5" width="70%" />
        <v-form class="mb-16" @submit.prevent="login" :class="$vuetify.breakpoint.mobile ? '': ''">
            <alert 
              v-if="errs && (errs.email || errs.password || errs.status)"
              :show = "errs && (errs.email || errs.password || errs.status)"
              :text = "errorMessage"
              type = "error"
            />
            <v-alert type="success" text v-if="success" 
            :class="$vuetify.breakpoint.mobile? 'mb-n7':'mb-5'">
                Login success! Redirecting you to LMS ...
            </v-alert>
            <v-text-field outlined dense placeholder="Email Address" 
                type="email"
                v-model="form.email"
                class="login-custom-field my-5 f14 roboto fw500 secondary-1--text" 
                hide-details
                append-icon="mdi-email-outline"
                color="l-primary"
                />
                
            <v-text-field outlined dense placeholder="Password" 
                :type="!show?'password':'text'"
                v-model="form.password"
                class="login-custom-field f14 roboto fw500 secondary-1--text" 
                hide-details 
                :append-icon="!show?'mdi-eye-outline':'mdi-eye-off-outline'"
                @click:append="show=!show"
                color="l-primary"
                />
            <div class="d-flex justify-end align-center my-5 primary-font">
                <!-- <router-link class="text-decoration-none"> -->
                    <b @click="$router.push({ name: 'Forgot Password'})" class="pointer-cursor mt-1 mx-1 l-primary--text f14">
                    Forgot Password
                    </b>
                <!-- </router-link> -->
            </div>
            <v-btn 
                large 
                block 
                color="csc_gradient_bg white--text" 
                style="border-radius: 10px" 
                type="submit"
                >
            <v-progress-circular
                v-if="logging"
                indeterminate
                width="5"
                color="#fff"/>
                <div class="primary-font" v-else>
                    Login
                </div>
                </v-btn>
        </v-form>
        <div class="primary-font text-center f14 fw500">
            <b>No account yet? <span @click="$emit('toRegister')" class="l-primary--text pointer-cursor">Sign up</span></b>
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'LoginView',
    props: ['toCheckout'],
    data: () => ({
        form: { email: '', password: '' },
        show: false,
        logging: false,
        success: false,
        images: {},
        errs: [],
    }),
    mounted() {
        this.getCustomizationAction().then(res => {
            this.images = res
        })
    },
    methods: {
        ...mapActions(['loginAction', 'getCustomizationAction']),
        ...mapActions('usr', ['addToCart']),

        async login() {
            this.logging=true
            this.errs = []
            this.loginAction(this.form).then(res => {
                if(res.email_verified_at) {
                    if( res.status === 'active') {
                        if(res.role === 'USER') {
                            if(localStorage.getItem('cart')) {
                                if(JSON.parse(localStorage.getItem('cart')).length > 0) {
                                    let _cart = JSON.parse(localStorage.getItem('cart'))
                                    let _course_ids = []
                                    _cart.forEach(_item => {
                                        _course_ids.push(_item.id)
                                    })
                                    this.addToCart({ course_id: _course_ids }).then(() => {
                                        localStorage.removeItem('cart')
                                        setTimeout(() => {
                                            this.$router.push({ name: 'User Dashboard'})
                                        }, 2000)
                                    })
                                } else {
                                    localStorage.removeItem('cart')
                                    this.$router.push({ name: 'User Dashboard'})
                                }
                            } else this.$router.push({ name: 'User Dashboard'})
                        }
                    }
                } else {
                    this.logging = false
                    this.$router.push({ name: 'Unverified'})
                }
            }).catch(e => {
                this.errs = e
                this.logging = false
            })
        }
    },
    computed: {
        ...mapState({
            errors: (state) => state.errors,
        }),

        errorMessage(){
        if(this.errs.email) {
                if(this.errs.email[0] === 'Invalid email or password. Please check your credentials and try again.') {
                return 'No such email or password'
                } else return this.errs.email[0]
        } else if (this.errs.password) {
                if(this.errs.password[0] === 'Invalid email or password. Please check your credentials and try again.') {
                return 'No such email or password'
                } else return this.errs.password[0]
        } else if (this.errs.status) {
                return this.errs.status[0]
        } else return 'Something went wrong. Please try again later.'
        }
    }
}
</script>